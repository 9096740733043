<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? $t("AJOUTER_UN") : $t("MODIFICATION_DU") }} {{$t("SICKLEAVES")}}  </h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
    <div class="p-6">
        <p class="pt-2" >{{ $t("entitled") }}<b style="color: #ff6141" >*</b></p>
        <vs-input 
              name="entitled" 
              v-validate="'required'"
              class="w-full" 
              v-model="input.entitled">
        </vs-input>
        <span  class="text-danger text-sm" v-show="errors.has('entitled')">{{$t("entitled_error")}}</span>
        
        <p class="pt-2" >{{ $t("startDate") }}<b style="color: #ff6141" >*</b></p>
        <div>
            <flat-pickr 
            :config="configFromdateTimePicker"
             name="startDate" 
             v-validate="'required'"
             class="w-full"
             v-model="input.startDate" 
         />
        </div>
        <span class="text-danger text-sm pt-2" v-show="errors.has('startDate')">{{$t("startDate_error")}}</span>
        
        <p class="pt-2"  >{{ $t("Période") }}<b style="color: #ff6141" >*</b></p>
        <v-select
            v-validate="'required'"
            name="periodeCheck"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mb-4 " 
            v-model="periodeCheck"
            :options="periodes">
          </v-select>
          <span  class="text-danger text-sm mb-4" v-show="errors.has('periodeCheck')">{{$t("periodeCheck_error")}}</span>

          <p class="pt-2" v-if="periodeCheck==='day'" >{{ $t("day") }}<b style="color: #ff6141" >*</b></p>
          <vs-input-number
          v-if="periodeCheck==='day'"
          min="0" 
          v-validate="'required'"
          name="day"
          v-model="input.nbOfDays"
          class="w-full mt-2"/>
         <span v-if="periodeCheck==='day'" class="text-danger text-sm" v-show="errors.has('day')">{{$t("day_error")}}</span>
         <span v-if="input.nbOfDays<=0 && periodeCheck==='day' " class="text-danger text-sm">{{$t("day_up_error")}}</span>

        
         <p class="pt-2" v-if="periodeCheck==='week'" >{{ $t("week") }}<b style="color: #ff6141" >*</b></p>
        <vs-input-number
        v-if="periodeCheck==='week'"
        min="0" 
        v-validate="'required'"
        name="week"
        v-model="input.nbOfWeeks"
        class="w-full mt-2"/>
        <span v-if="periodeCheck==='week'" class="text-danger text-sm" v-show="errors.has('week')">{{$t("week_error")}}</span>
        <span v-if="input.nbOfWeeks<=0 && periodeCheck==='week'" class="text-danger text-sm">{{$t("week_up_error")}}</span>

        
        <p class="pt-2" v-if="periodeCheck==='month'" >{{ $t("month") }}<b style="color: #ff6141" >*</b></p>
          <vs-input-number
          v-if="periodeCheck==='month'"
          min="0" 
          v-validate="'required'"
          name="month"
          v-model="input.nbOfMonths"
          class="w-full mt-2"/>
        <span v-if="periodeCheck==='month'" class="text-danger text-sm" v-show="errors.has('month')">{{$t("month_error")}}</span>
        <span v-if="input.nbOfMonths<=0 && periodeCheck==='month'" class="text-danger text-sm">{{$t("month_up_error")}}</span>

       
        <p class="pt-2"  >{{ $t("Motif") }}<b style="color: #ff6141" >*</b></p>
        <vs-input 
              name="reason" 
              v-validate="'required'"
              class="w-full" 
              v-model="input.reason">
        </vs-input>
        <span  class="text-danger text-sm" v-show="errors.has('reason')">{{$t("reason_error")}}</span>
        
    
          <p class="pt-2"  >{{ $t("patient") }}<b style="color: #ff6141" >*</b></p>
          <vs-select
              
              v-model="input.patient"
              v-validate="'required'"
              autocomplete
              clearable
              class="w-full mb-4"
              name="patient"
          >
          <vs-select-item :key="item" :value="item.id" :text="item.last_name" v-for="item in patients" /></vs-select>
          
          <span  class="text-danger text-sm" v-show="errors.has('patient')">{{$t("patient_error")}}</span>
          
          <p class="pt-2"  >{{ $t("additionalInformation") }}<b style="color: #ff6141" >*</b></p>
    
          <vs-textarea
          v-model="input.additionalInformation"
           class="w-full mb-4 " 
            rows="5"
            v-validate="'required'"
            name="additionalInformation"
            label="Informations Complémentaires"  />
            <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('additionalInformation')">{{$t("additionalInformation_error")}}</span>


       </div>
      </component>
  
      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button :disabled="!(validate_nbOfDays)" v-if="periodeCheck==='day'" class="mr-6" @click="category_validate">{{$t("Soumettre")}} </vs-button>
        <vs-button :disabled="!(validate_nbOfWeeks)" v-if="periodeCheck==='week'" class="mr-6" @click="category_validate">{{$t("Soumettre")}} </vs-button>
        <vs-button :disabled="!(validate_nbOfMonths)" v-if="periodeCheck==='month'" class="mr-6" @click="category_validate">{{$t("Soumettre")}} </vs-button>
        <vs-button v-if="periodeCheck===null" class="mr-6" @click="category_validate">{{$t("Soumettre")}} </vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Annuler")}}</vs-button>
      </div>
    </vs-sidebar>
  </template>
  
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'

const input_tempon = {
  entitled: '',
  startDate: '',
  nbOfDays:'',
  nbOfWeeks:'',
  nbOfMonths:'',
  reason: '',
  patient: '',
  additionalInformation: ''
}
  
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar,
    flatPickr

  },
  data () {
    return {
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        maxDate: null,
        locale: FrenchLocale
      },
      configTodateTimePicker: {
        minDate: null,
        locale: FrenchLocale
      },
      input: JSON.parse(JSON.stringify(input_tempon)),
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      periodeCheck:null,
      periodes: [
        {name: 'Jour', key: 'day'},
        {name: 'Semaine', key: 'week'},
        {name: 'Mois', key: 'month'}

      ],
      patients: []
     
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.input = JSON.parse(JSON.stringify(this.data))
        this.input.patient = this.input.patient.id
          
        this.initValues()
      }
    }
  },
  
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },

    validate_nbOfDays () {
      if (
        (
          this.input.nbOfDays !== '' && this.input.nbOfDays > 0 
     
        )) return true
    },
    validate_nbOfWeeks () {
      if (
        (
          this.input.nbOfWeeks !== '' && this.input.nbOfWeeks > 0 
     
        )) return true
    },
    validate_nbOfMonths () {
      if (
        (
          this.input.nbOfMonths !== '' && this.input.nbOfMonths > 0 
     
        )) return true
    }
  },
  methods: {
    onFromChange (selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, 'minDate', dateStr)
    },
    onToChange (selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, 'maxDate', dateStr)
    },
    initValues () {
      if (this.data.id) return
      this.input = JSON.parse(JSON.stringify(input_tempon))
    },
    category_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
  
    async submitData () {
      this.$vs.loading()

      const input = JSON.parse(JSON.stringify(this.input))
      

      let url = 'sickLeaves/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('sickLeaves_save')
      }
      if (input.id) {
        url += `${input.id}/`
        methods = 'put'
        message.success = this.$t('sickLeaves_update')
      }
  
      this.$http[methods](url, input)
        .then((response) => {
          window.sickLeaves.getAllsickLeaves()
          window.getPrendTaCom.success(message.success, response)
          this.periodeCheck = ''
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
  
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'entitled') {
              libelle = this.$t('entitled')
            }
            if (item === 'startDate') {
              libelle = this.$t('startDate')
            }
           
            if (item === 'reason') {
              libelle = this.$t('reason')
            }
            if (item === 'patient') {
              libelle = this.$t('patient')
            }
            if (item === 'additionalInformation') {
              libelle = this.$t('additionalInformation')
            }
            if (item === 'nbOfDays') {
              libelle = this.$t('nbOfDays')
            }
            if (item === 'nbOfWeeks') {
              libelle = this.$t('nbOfWeeks')
            }
            if (item === 'nbOfMonths') {
              libelle = this.$t('nbOfMonths')
            }
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }
  
          window.getPrendTaCom.error(message.error)
        })
    }
    
  },
  async created () {
    this.$http.get('patients/')
      .then((response) => { this.patients = response.data })
      .catch(() => { })

    this.$http.get('dentists/')
      .then((response) => {
        this.dentists = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
  }
}
</script>
  
  <style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }
  
    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;
  
      .img-upload {
        margin-top: 2rem;
  
        .con-img-upload {
          padding: 0;
        }
  
        .con-²-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  
  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  
    &:not(.ps) {
      overflow-y: auto;
    }
  }
  </style>
  